<!--
@Author: Calvin Green <calvin>
@Date:   2021-05-12T21:25:16+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-07-02T13:08:50+02:00
@Copyright: Diversitude 2021
-->



<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { initSpreadifyBackend, getSpreadifyBackend} from '../../../helpers/spreadify.js'
//import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import DecoupledEditor from "../../../plugin/ckeditor-custom/ckeditor.js"

/**
 * Advanced table component
 */
export default {
  page: {
    title: "TestZone",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "TestZone",
      items: [
        {
          text: "Admin",
          href: "/",
        },
        {
          text: "TestZone",
          active: true,
        },
      ],
      pdfOn: false,
      pdf64: "",

      html_body: '',
    };
  },
  computed: {

  },
  methods: {
    logHTML(){
      let data = window.editor.getData()
      console.log(data);
    },
    initEditor(){
            DecoupledEditor
            .create( document.querySelector( '.document-editor__editable' ),{
                table: {
                    contentToolbar: [
                        'tableColumn', 'tableRow', 'mergeTableCells',
                        'tableProperties', 'tableCellProperties'
                    ],
                    tableProperties: {},

                    tableCellProperties: {}
                }
            })
            .then( editor => {
                const toolbarContainer = document.querySelector( '.document-editor__toolbar' );
                toolbarContainer.appendChild( editor.ui.view.toolbar.element );
                window.editor = editor;
            })
            .catch( err => {
                console.error( err );
            });
        },
      renderUploadOptions() {
          var storage = window.localStorage;
          var AUTH_TOKEN = storage.getItem('x_token');
          var url = process.env.VUE_APP_API_BASEURL + 'api/clientapp/uploadtemplate';
          var options = {
              target: url,
              headers: {
                  'Access-Control-Allow-Credentials': true,
                  'X-Requested-With': 'XMLHttpRequest',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer ' + AUTH_TOKEN,
              },
              query: {
                  client_app_id: "",
                  client_app_report_id: "",
                  name: "",
                  description: "",
                  tag: ""
              },
              testChunks: false,
              fileParameterName: 'template', //Set the upload post param which will contain the binary payload
              singleFile: false, //Limit upload to one file
          };
          return options;
      },
      uploadSheet() {
          const uploaderInstance = this.$refs['tool_upload'];
          const uploader = uploaderInstance.uploader;
          uploader.opts.query.client_app_report_id = 2;
          uploader.opts.query.client_app_id = 1;
          uploader.opts.query.name =  "Testing";
          uploader.opts.query.description = "Testing";
          uploader.opts.query.tag = "testing";
          uploader.resume();
          uploader.on('fileSuccess', function (rootFile, file, message) {
              var response = JSON.parse(message);
              // eslint-disable-next-line no-console
              console.log("response", response);
              if (response.success) {
                  Swal.fire("Uploaded!", "Spreadsheet Uploaded", "success");
              }
              //alert("Upload error!");
              uploader.off('fileSuccess');
              uploader.cancel();
         });
     },
     previewReport: function() {
         this.pdfOn = false;
         window.axios.post('api/clientapp/previewreport', {id: 1})
         .then((response)=>{
             // eslint-disable-next-line no-console
             //console.log(response.data);
             this.pdf64 = response.data.b64;
             this.$nextTick(() => {
                 this.pdfOn = true;
             });
         })
         .catch((e)=>{
             // eslint-disable-next-line no-console
             console.log(e);
         })
     }
  },
  mounted() {
    initSpreadifyBackend('9|Xco8yu4GmOyehJbvnlhAQdu54DBYXmyS180yBAv8');
    setTimeout(()=>{
      console.log('Go go go')
      getSpreadifyBackend().getViewConfig('618a3c188efea')
    }, 1000);
    
    // Set the initial number of items
    this.initEditor()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-button @click="logHTML">Log HTML</b-button>

    <div class="row">
      <div class="col-sm-12">
        <div class="document-editor">
            <div class="document-editor__toolbar"></div>
            <div class="document-editor__editable-container">
                    <div class="document-editor__editable">
                        <p>{{html_body}}</p>
                    </div>
            </div>
        </div>
      </div>
        <!--<div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                  <div class="col-sm-12">
                      <h5>Upload Spreadsheet</h5>
                      <uploader :options="renderUploadOptions()" :autoStart="false" :ref="'tool_upload'" class="uploader-example">
                          <uploader-unsupport></uploader-unsupport>
                              <uploader-drop>
                                  <p>Drop file here to upload or</p>
                                  <uploader-btn type="button" :single="false"><i class="fas fa-file fa-fw"></i> Select file</uploader-btn>&nbsp;
                              </uploader-drop>
                          <uploader-list></uploader-list>
                      </uploader>
                      <button type="button"  @click="uploadSheet()" class="btn btn-primary">Upload Sheet</button>
                  </div>
              </div>
            </div>
        </div> -->

        <!--
        <div class="col-sm-12">
            <button type="button"  @click="previewReport()" class="btn btn-primary">Preview Report</button>
        </div>
        <div class="col-sm-12" v-if="pdfOn">
            <iframe :src="'data:application/pdf;base64,' + pdf64" style="width:100%; height:700px;" frameborder="0"></iframe>
        </div>
        -->
    </div>

    <hr>
    <div style="width: 100%; border: 1px solid red;">
      <table style="border-color:hsl(0, 0%, 30%);border-style:solid;"><tbody><tr><td style="height:100px;width:100%;" colspan="4">Logo</td></tr><tr><td colspan="4">N ame</td></tr><tr><td style="width:150px;" colspan="4">Description</td></tr><tr><td style="width:150px;"><strong>SOP No:</strong></td><td style="width:70%;">&nbsp;</td><td style="width:10%;"><strong>Effective Date:</strong></td><td style="width:10%;">&nbsp;</td></tr><tr><td style="width:10%;"><strong>Revision No:</strong></td><td>&nbsp;</td><td><strong>Supersedes:</strong></td><td>&nbsp;</td></tr><tr><td><strong>Revise By:</strong></td><td>&nbsp;</td><td colspan="2">&nbsp;</td></tr></tbody></table>

    </div>
  </Layout>
</template>
<style>
table, th, td {
  border: 1px solid black;
}
#table{
  width: 100%;
}
</style>
